<template>
  <a-form-item label="币种" name="currencyType">
    <div style="height:0">
      {{ config.currencyType }}
    </div>
    <a-select
      v-model:value="config.currencyType"
      :options="options"
      placeholder="请选择"
      :disabled="config?.allDisabled"
    >
    </a-select>
  </a-form-item>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineProps, ref, onMounted } from 'vue'
const { config } = defineProps(['config'])
// defineProps(['config'])
const options = ref([
  {
    value: '人民币',
    label: '人民币'
  },
  {
    value: '美元',
    label: '美元'
  },
  {
    value: '欧元',
    label: '欧元'
  },
  {
    value: '英镑',
    label: '英镑'
  },
  {
    value: '日元',
    label: '日元'
  },
  {
    value: '港元',
    label: '港元'
  }
])
const store = useStore()

// const handchange = (value) => {
//   console.log(store.state.process.selectFormItem);
//   console.log(config.currencyType);
// }
</script>

<style lang="less" scoped></style>
